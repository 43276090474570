import { ChangeEvent, FormEvent } from 'react';
import { Search } from './search';
import { StateItem, useStateItem } from './stateItem';
import { RefItem, useRefItem } from './refItem';

export class SearchForm {
  constructor(
    private search: Search,
    private searchText: StateItem<string>,
    private errorMessage: StateItem<string>,
    private isSubmitting: RefItem<boolean>,
  ) {}

  public getSearchText(): string {
    return this.searchText.get(true);
  }

  public onTextChanged(e: ChangeEvent<HTMLTextAreaElement>): void {
    this.clearErrorMessage();
    const text = e.target.value;
    this.searchText.set(text);
  }

  public getErrorMessage(): string {
    return this.errorMessage.get(true);
  }

  public setErrorMessage(message: string) {
    this.errorMessage.set(message);
  }

  private clearErrorMessage() {
    Boolean(this.getErrorMessage()) && this.setErrorMessage('');
  }

  public async onSubmit(): Promise<void> {
    this.clearErrorMessage();
    if (this.isSubmitting.get()) return;
    const question = this.searchText.get(true).trim();
    if (!Boolean(question)) {
      this.setErrorMessage(' Empty input, please ask a question.');
      return;
    }
    this.isSubmitting.set(true);
    try {
      await this.search.create(question);
      this.searchText.set('');
      if (!this.isSubmitting) return;
    } catch (error) {
      this.setErrorMessage(
        error.message ?? ' Something went wrong. Please try again.',
      );
    } finally {
      this.isSubmitting.set(false);
    }
  }

  public isDisabled(): boolean {
    return this.isSubmitting.get();
  }
}

export function useSearchForm(search: Search) {
  return new SearchForm(
    search,
    useStateItem(''),
    useStateItem(''),
    useRefItem(false),
  );
}
