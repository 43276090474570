import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { trpc } from 'lib/trpc';
import {
  MAX_ANONYMOUS_QUESTIONS,
  Source,
  qnaAPI,
  type StatisticsBarResponse,
  type StatisticsLineResponse,
  type StatisticsPieResponse,
  type StatisticsResponse,
} from './qnaAPI';
// import {
//   HOURS_IN_DAY,
//   MINUTES_IN_HOUR,
//   SECONDS_IN_MINUTE,
//   MILLISECONDS_IN_SECOND,
// } from 'constants/time';
import { StateItem, useStateItem } from './stateItem';
import { analytics } from 'lib/analytics';
import {
  AnonymousQuestionAnswer,
  useAnonymousQuestionAnswer,
} from './anonymousQuestionAnswer';
import { useRouter } from 'next/navigation';

type modalType = 'signup' /*  | 'login' */ | null;

export class AnonymousSearch {
  constructor(
    private router: AppRouterInstance,
    private question: StateItem<string>,
    private title: StateItem<string>,
    private sources: StateItem<Source[]>,
    private statistics: StateItem<StatisticsResponse[]>,
    private tags: StateItem<string[]>,
    private anonymousQAsCount: StateItem<number>,
    private openModal: StateItem<modalType>,
    private anonymousQA: AnonymousQuestionAnswer,
  ) {}

  public getQuestion(latest: boolean): string {
    return this.question.get(latest);
  }

  public getTitle(latest: boolean): string {
    return this.title.get(latest);
  }

  public getSources(latest: boolean): Source[] {
    return this.sources.get(latest);
  }

  public getStatistics(latest = false): StatisticsResponse[] {
    return this.statistics.get(latest);
  }

  public getTags(latest: boolean): string[] {
    return this.tags.get(latest);
  }

  public getOpenModal(latest: boolean): modalType {
    return this.openModal.get(latest);
  }

  public setOpenModal(value: modalType): void {
    this.openModal.set(value);
  }

  public getAnonymousQA(): AnonymousQuestionAnswer[] {
    return [this.anonymousQA];
  }

  public getAnonymousQAsCount(latest: boolean): number {
    return this.anonymousQAsCount.get(latest);
  }

  private async fetchTitle(question: string): Promise<void> {
    try {
      const title = await qnaAPI.fetchTitle(question);
      this.title.set(title);
    } catch (error) {
      console.error('ERROR FETCHING TITLE: ', error?.message ?? '');
      //if error fetchin title, set title to question
      this.title.set(question);
    }
  }

  /**
   * Whether any of the questions' answers are streaming.
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsStreaming(latest: boolean): boolean {
    return this.anonymousQA.getIsStreaming(latest);
  }

  private async fetchSources(question: string, queryId: string): Promise<void> {
    try {
      const sources = await qnaAPI.fetchSources(question, 1, queryId);
      this.sources.set(sources);
    } catch (error) {
      console.error('ERROR FETCHING TITLE: ', error?.message ?? '');
    }
  }

  private async fetchStatistics(): Promise<void> {
    const randDataPoints = Math.floor(Math.random() * (10 - 3 + 1)) + 3;
    const chartType =
      Math.random() > 0.66 ? 'line' : Math.random() > 0.5 ? 'bar' : 'pie';

    const generatePieBarStatistics = ():
      | StatisticsPieResponse
      | StatisticsBarResponse => {
      const data = [];
      for (let i = 0; i < randDataPoints; i++) {
        data.push({
          id: i,
          label: `Label ${i + 1}`,
          value: Math.floor(Math.random() * 100) + 1,
        });
      }
      return {
        type: chartType === 'pie' ? 'pie' : 'bar',
        graphTitle: `${chartType === 'pie' ? 'Pie' : 'Bar'} Chart`,
        data,
      };
    };

    const generateLineStatistics = (): StatisticsLineResponse => {
      const randLines = Math.floor(Math.random() * 5) + 1;
      const data = [];
      for (let i = 0; i < randLines; i++) {
        const dataPoints = [];
        for (let j = 0; j < randDataPoints; j++) {
          dataPoints.push({
            x: `Point ${j + 1}`,
            y: Math.floor(Math.random() * 100) + 1,
          });
        }
        data.push({
          id: i,
          label: `Label ${i + 1}`,
          data: dataPoints,
        });
      }
      return {
        type: 'line',
        graphTitle: 'Line Chart',
        data,
      };
    };

    console.log('fetching statistics');
    this.statistics.set([
      chartType === 'line'
        ? generateLineStatistics()
        : generatePieBarStatistics(),
    ]);
  }

  private async fetchTags(question: string): Promise<void> {
    try {
      const tags = await qnaAPI.fetchTag(question);
      this.tags.set(tags);
    } catch (error) {}
  }

  private async getAnynomousQAsCount(anonymousId: string) {
    return await trpc.chat.getUnAuthQAsCount.query(anonymousId);
  }

  private async setTitleSourcesTagsFromQueryResult(
    question: string,
    title: string,
    sources: Source[],
    tags: string[],
  ) {
    await this.fetchStatistics();
    this.title.set(title);
    this.sources.set(sources);
    this.tags.set(tags);
    this.anonymousQA.setTitle(title);
    this.anonymousQA.setSources(sources);
    this.anonymousQA.setSources(this.sources.get(true));
    this.anonymousQA.setTags(tags);
  }

  private async fetchAndSetTitleSourcesTags(question: string, queryId: string) {
    await this.fetchTitle(question);
    await this.fetchSources(question, queryId);
    await this.fetchStatistics();
    await this.fetchTags(question);
    this.anonymousQA.setTitle(this.title.get(true));
    this.anonymousQA.setSources(this.sources.get(true));
    this.anonymousQA.setTags(this.tags.get(true));
  }

  // Return cached answer if it exists and is within the cache ttl, otherwise create a new answer
  // used for suggested queries for anonymous users.
  public async returnCachedOrCreate(
    queryId: string,
    question: string,
    anonymousId: string,
    // cache_ttl_in_days: number,
  ): Promise<void> {
    this.question.set(question);
    this.clear();
    const result = await trpc.chat.getUnauthQuestionAnswer.query({
      anonymousId,
      queryId,
    });

    this.anonymousQA.setQueryId(queryId);
    this.anonymousQA.setAnonymousId(anonymousId);
    this.anonymousQA.setQuestion(question);
    window?.location?.pathname === '/' && this.router.push('/search');

    // if the result is not null and the createdAt date is within the cache ttl
    // get the title, sources, tags, and answer from the result
    if (
      result
      // new Date(result.createdAt) >
      //   new Date(
      //     new Date().getTime() -
      //       cache_ttl_in_days *
      //         HOURS_IN_DAY *
      //         MINUTES_IN_HOUR *
      //         SECONDS_IN_MINUTE *
      //         MILLISECONDS_IN_SECOND,
      //   )
    ) {
      this.setTitleSourcesTagsFromQueryResult(
        question,
        result.title ?? question,
        result.sources as unknown as Source[],
        result.tags.map((tag) => tag.text),
      );
      this.anonymousQA.setAnswer(result.answer);
    } else {
      // if the result is null or the createdAt date is outside the cache ttl
      // fetch title, sources, and tags from qnaAPI
      this.fetchAndSetTitleSourcesTags(question, queryId);

      // stream new updated answer from qnaAPI, and set the answer in DB.
      // send deleteExisting as true to delete any existing answer to suggested query cached in DB
      await this.anonymousQA.streamAnonymousAnswer(true /* deleteExisting */);
    }
  }

  public async create(question: string): Promise<void> {
    this.question.set(question);
    let anonymousId, count;
    const existingAnonymousId = Cookies.get('cleeai_anonymous_id') ?? '';

    if (Boolean(existingAnonymousId)) {
      anonymousId = existingAnonymousId;
      count = await this.getAnynomousQAsCount(existingAnonymousId);

      if (count >= MAX_ANONYMOUS_QUESTIONS) {
        this.openModal.set('signup');
        return;
      }
    } else {
      anonymousId = uuid();
      count = 0;
      Cookies.set('cleeai_anonymous_id', anonymousId, {
        expires: 365,
        path: '/',
        domain: process.env.NEXT_PUBLIC_APP_DOMAIN,
        sameSite: 'strict',
        secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
      });
    }

    this.clear();
    const queryId = uuid();

    this.anonymousQAsCount.set(count);

    analytics.questionAskedAnonymous({
      question,
      anonymousId,
    });

    this.anonymousQA.setQueryId(queryId);
    this.anonymousQA.setAnonymousId(anonymousId);
    this.anonymousQA.setQuestion(question);

    window?.location?.pathname === '/' && this.router.push('/search');

    await this.fetchTitle(question);
    await this.fetchSources(question, queryId);
    await this.fetchStatistics();
    await this.fetchTags(question);

    await this.anonymousQA.streamAnonymousAnswer();
    const answer = this.anonymousQA.getAnswer(true);

    analytics.questionAnsweredAnonymous({
      question,
      queryId,
      tags: this.getTags(true),
      sources: this.getSources(true),
      answer,
      anonymousId,
    });
  }

  private clear() {
    this.title.set('');
    this.sources.set([]);
    this.statistics.set([]);
    this.tags.set([]);
    this.anonymousQAsCount.set(0);
    this.openModal.set(null);
    this.anonymousQA.clear();
  }
}

export function useAnonymousSearch() {
  const newAnonymousQA = useAnonymousQuestionAnswer();
  return new AnonymousSearch(
    useRouter(),
    useStateItem(''), //question
    useStateItem(''), //title
    useStateItem([]), //sources
    useStateItem([]), //statistics
    useStateItem([]), //tags
    useStateItem(0), //anonymousQAsCount
    useStateItem(null), //openModal
    newAnonymousQA,
  );
}
