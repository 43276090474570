import { trpc } from 'lib/trpc';
import { TagSearch } from 'lib/types';
import { StateItem, useStateItem } from './stateItem';
import { makeCompare } from './makeCompare';

export type TagAction = 'add' | 'remove';
export class SavedSearches {
  constructor(
    private taggedSearches: StateItem<TagSearch[]>,
    private openedTags: StateItem<string[]>,
  ) {}

  // Sorts tagged searches by the most recent search in each tag
  private compareTagSearches(a: TagSearch, b: TagSearch) {
    if (a.searches.length === 0) return 1;
    if (b.searches.length === 0) return -1;
    return b.searches[0].id - a.searches[0].id;
  }

  public async fetch() {
    const results = await trpc.search.getTaggedSearches.query();
    const taggedSearches: TagSearch[] = [];
    for (const tag of Object.keys(results)) {
      const tagSearches = results[tag].filter((search) => search.title.length);

      // Sort searches by most recent first using the id, because id is an serial auto-incrementing numeric field
      tagSearches.sort(makeCompare('id')).reverse();
      if (tagSearches.length)
        taggedSearches.push({ tag, searches: tagSearches });
    }
    taggedSearches.sort(this.compareTagSearches);
    this.taggedSearches.set(taggedSearches);

    if (Boolean(taggedSearches.length)) {
      const firstTag = this.taggedSearches.get(true)[0].tag;
      const currentOpenedTags = this.getOpenedTags(true);
      if (!currentOpenedTags.includes(firstTag)) {
        this.setOpenedTags(firstTag, 'add');
      }
    }
  }

  public get(): TagSearch[] {
    return this.taggedSearches.get();
  }

  public remove(searchId: string) {
    const taggedSearches = this.taggedSearches.get(true);
    const newTaggedSearches = taggedSearches
      .map((tagSearch) => {
        const searches = tagSearch.searches.filter(
          (search) => search.queryId !== searchId,
        );
        return { ...tagSearch, searches };
      })
      .filter((tagSearch) => tagSearch.searches.length);
    this.taggedSearches.set(newTaggedSearches);
    trpc.search.deleteSearch.mutate(searchId);
  }

  /**
   * Retrieve opened tags
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns a list of all opened tags by the user;
   */
  public getOpenedTags(latest: boolean): string[] {
    return this.openedTags.get(latest);
  }

  /**
   * Set opened tags
   *
   * @param tag the tag to be added or removed;
   * @param action add or remove tag from list;
   */
  public setOpenedTags(tag: string, action: TagAction) {
    const currentOpenedTags = this.openedTags.get(true);
    const updatedOpenedTags =
      action === 'add'
        ? [...currentOpenedTags, tag]
        : currentOpenedTags.filter((item) => item !== tag);
    this.openedTags.set(updatedOpenedTags);
  }
}

export function useSavedSearches(): SavedSearches {
  return new SavedSearches(
    useStateItem([]), //taggedSearches
    useStateItem([]), //openedTags
  );
}
